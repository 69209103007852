.ant-tree-title {
  color: #6B7280;
  font-family: 'Inter', sans-serif;
  font-size: small;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #6b7280;
  border-color: #6b7280;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-tree .ant-tree-checkbox-wrapper-checked:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner,
:where(.css-dev-only-do-not-override-15rg2km).ant-tree .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
  background-color: #6b7280;
  border-color: #6b7280;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}


.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  /* min-height: 48px;
  width: 480px; */
  padding: 0 8px;
  border: 1px solid #d0d5dd81;
  border-radius: 6px;
}

.tags-input:focus-within {
  border: 1px solid #d0d5dd81;
}

.tags-input input {
  flex: 1;
  border: none;
  margin: auto 0;
  font-size: 14px;
}

.tags-input input:focus {
  outline: transparent;

}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #03543F;
  padding: 11px 12px;
  font-size: 14px;
  list-style: none;
  border-radius: 13px;
  margin: 0 8px 8px 0;
  background: #DEF7EC;
}

.tag .tag-title {
  margin-top: 0px;
}

.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  color: #03543F;
  border-radius: 50%;
  cursor: pointer;
}