@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.css-myjcs-control {
  box-shadow: 0px !important;
  border-color: #FF4405 !important;
}

.css-myjcs-control:hover {
  border-color: #FF4405 !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.2s;
}

.MuiDataGrid-cell {
  padding-right: 10px !important;
}

.MuiDataGrid-row {
  background-color: transparent !important;
  cursor: pointer;
}

.Mui-selected {
  background-color: transparent !important;
}

.Mui-selected:hover {
  background-color: transparent !important;
}

/* .css-1p3m7a8-multiValue {
  border-radius: 80px !important;
} */

/* .react-select__option:hover {
  background-color: #FFF4ED !important;
} */

.MuiTablePagination-root .MuiTablePagination-selectRoot {
  display: none !important;
}

.react-select-container  {
  z-index: 5 !important;
}

.react-tagsinput-tag {
  background-color: rgb(225 239 254) !important;
  color: rgb(30 66 159) !important;
  border-radius: 10px !important;
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
  border: none !important;
}

.react-tagsinput {
  border-radius: 0.5rem !important;
  max-height: 100px;
  overflow-y: scroll !important;
  padding: 0.5rem !important;
}

.react-tagsinput--focused {
  border-radius: 0.5rem !important;
  max-height: 100px;
  overflow-y: scroll !important;
  padding: 0.5rem !important;
  border-color: rgb(209 213 219) !important;
}

.react-tagsinput-input {
  max-width: 1000px !important;
  width: auto !important;
  font-size: 14px !important;
}